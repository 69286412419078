<template>
    <div class="cal-card pl24 pt24 pr24 pb24 w100" ref="cal"></div>
    <CustomCalendarPopOver
        ref="popover"
        @close="closePopover"
        @delete="deleteEvent"
        @view-campaign="viewCampaign"
        @insert-post="associate"
        :event="popoverEvent"
        :key="popoverEvent ? 'popover'+popoverEvent.id :'popover'"
    ></CustomCalendarPopOver>
    <FacebookAddPost
        v-if="showFbAddPanel"
        @close="onClosePanel"
        @add-success="onPostAdded"
        :campaign="campaign"
        :startStep="1"
        :social ="social"
        :eventId="popoverEventId"
        @oauth-error="resettingToken = true"
    ></FacebookAddPost>
    <InstagramAddMedia
        v-if="showIgAddPanel"
        @close="onClosePanel"
        @add-success="onPostAdded"
        :campaign="campaign"
        :label="labels.PICK_MEDIA"
        :calendarId="creatingCalendarId"
        :eventId="popoverEventId"
        :social ="social"
        @oauth-error="resettingToken = true"
    ></InstagramAddMedia>
     <FacebookResetToken
        v-if="resettingToken"
        @close="resettingToken = false"
    ></FacebookResetToken>
</template>

<script>
import { ref,onMounted, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { API } from '@/api/API';
import { ApiEndPoints } from '@/api/Endpoints'
import { Calendar } from '@fullcalendar/core';
import itLocale from '@fullcalendar/core/locales/it'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import CustomCalendarPopOver from '@/components/home/CustomCalendarPopOver'
import {timeStampToFormattedDate} from '@/utils/DateTimeUtils'
import FacebookAddPost from '@/components/campaigns/facebook/panels/FacebookAddPost'
import FacebookResetToken from '@/components/FacebookResetToken'
import InstagramAddMedia from '@/components/campaigns/instagram/panels/InstagramAddMedia'
import {labels} from '@/utils/InstagramUtils'
import {EventsHandler} from '@/utils/EventsHandler'
export default {
    name:'calendarCard',
    components:{
        CustomCalendarPopOver,
        FacebookAddPost,
        FacebookResetToken,
        InstagramAddMedia
    },
    props:{
        events:{
            type:Array,
            default:()=>[]
        }
    },
    setup(){
        const cal = ref(null);
        const campaign = ref(null);
        const social = ref(null);
        const resettingToken = ref(false)
        const creating= ref(false)
        const store = useStore()
        const router = useRouter()
        let start = new Date()
        let month = start.getMonth()
        start.setMonth(month-1)
        let end = new Date()
        month = end.getMonth()
        end.setMonth(month+1)

        let today =   new Date()
        start = today.setDate(1)  

        const dates = reactive({
            start:new Date(start).toISOString(),
            end:new Date(end).toISOString()
        })

       

        let calendar
        /**
         * funzioni custom per il calendario
         */
        /**
         * al mount dei vari eventi eseguo i controlli per
         * la modifica dell'interfaccia (colori etc)
         */
        const onEventDidMount = (args)=>{
            const {el, event} = args
            const shared = event?.extendedProps?.extendedProperties.shared
            if(shared ){
                if(shared.eventType ==="postPublished"){
                    el.classList.add('green-evt')
                } else if(shared.eventType ==="shouldPublishPost") {
                    el.classList.add('red-evt')
                }
                const {discriminator} = shared
                if(discriminator){
                    el.setAttribute('data-discriminator', discriminator)
                }
            } 
        }

        
        /**
         * puntatore per il popover
         */
         let popoverEvent
         const popoverEventId = ref(null)
        const onEventClick = (calEvent )=> {
            
            popoverEvent = calEvent.event
            const discriminator = calEvent.el.getAttribute('data-discriminator')
            const textPrefix = discriminator === 'Facebook' ? 'fb ' : 'ig '
            const rect = calEvent.el.getBoundingClientRect()
            popover.value.$el.style.top = rect.top - 16 + 'px'
            let x = rect.left
            if((x+250) > (window.innerWidth - 24)) {
                x = window.innerWidth - 24 -250
            }
            
            popover.value.$el.style.left = x + 'px'
            popover.value.$el.querySelector('.popover-text').textContent = textPrefix + calEvent.el.querySelector('.fc-event-title')?.textContent
            popover.value.$el.querySelector('.fc-event-time span').textContent = timeStampToFormattedDate(calEvent.event.start) + ', ' +calEvent.el.querySelector('.fc-event-time')?.textContent
           
            const {event} = calEvent
            popoverEventId.value = event.id
            const postId = event?.extendedProps?.extendedProperties?.shared?.postId
            if(postId ){
                popover.value.$el?.querySelector('#insert').classList.add('hide')
            }else{
                popover.value.$el?.querySelector('#insert').classList.remove('hide')
            }

            popover.value.$el.classList.remove('hide')
        }




        const storeEvents = computed(()=>{
            return store.getters['calendar/events']
        }) 

        /**
         * al nuovo set dei dati ricostruisce la view
         */
        const onDatesSet = async function(args){
            if(dates.start !== args.start.toISOString() || dates.end !== args.end.toISOString()){
                dates.start = args.start.toISOString()
                dates.end = args.end.toISOString()
                await store.dispatch('calendar/queryDates', dates)
                const events = storeEvents.value
                const removeEvents = calendar.getEventSources();
                removeEvents.forEach(event => {
                    event.remove();
                });                
                calendar.addEventSource(events)
                calendar.refetchEvents() 
            }
           
        }
       
        
        //inizializzo il calendario    
        onMounted(async()=>{
            calendar = new Calendar(cal.value, {
                plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin ],
                initialView: 'dayGridMonth',
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                },
                eventTimeFormat: { 
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: false
                },
                events: storeEvents.value,
                allDaySlot:true,
                dayMaxEvents: 3,
                locale:itLocale,
                themeSystem:'cerulean',
                eventDidMount: onEventDidMount,
            });
            calendar.on('eventClick',onEventClick)
            calendar.on('datesSet',onDatesSet)
            calendar.render()
        })

        EventsHandler.subscribe('resize-calendar', ()=>{
            calendar.updateSize()
        })

        const popover = ref(null);
       
        const closePopover = () => {
           popover.value.$el.classList.add('hide')
        }  
       
        const deleteEvent = async() => {
            try {
                const event = calendar.getEventById(popoverEvent.id)
                const {extendedProperties} = event.extendedProps
                const {shared} = extendedProperties
                if(shared?.eventType === "shouldPublishPost"|| shared?.eventType === "postPublished"){
                    await store.dispatch('campaigns/deleteEvent',{
                        id: popoverEvent.id,
                        socialId:shared.socialId,
                        campaignId:shared.campaignId
                    })
                }else{
                    await store.dispatch('calendar/deleteEvent',{
                        id: popoverEvent.id.id
                    })
                }
                
                
                await store.dispatch('messages/message', {
                        title: 'evento Eliminato',
                        message:'evento eliminato correttamente'
                })
                closePopover() 
                
                event.remove() 
                
            } catch (error) {
              await store.dispatch('messages/message', {
                    title:"errore durante l'eliminazione ", 
                    message:error.message 
                })  
            }
        }

        const viewCampaign = ()=>{
           
            const shared = popoverEvent?.extendedProps?.extendedProperties.shared
            if(shared ){
                
                router.push('/campaigns/'+ shared.campaignId+ '?tab='+shared.discriminator.toLowerCase())
            } 
        }

        const associate = async()=>{
            
            const shared = popoverEvent?.extendedProps?.extendedProperties.shared
            
            if(shared ){
                
                const {data} = await API.get(ApiEndPoints.CAMPAIGNS+'/'+shared.campaignId)
                const endpoint = data.type === 'Instagram' ? ApiEndPoints.SOCIAL_IG : ApiEndPoints.SOCIAL_FB
                campaign.value = data
                const response = await API.get(endpoint+'/'+shared.socialId)
                social.value = response.data
                creating.value = true
            } 
           
        }

        const onPostAdded = ()=>{
            EventsHandler.publish('publish-date')
        }

        const showFbAddPanel = computed(()=> {
            return social.value && social.value.discriminator === 'Facebook' 
        })

        const showIgAddPanel = computed(()=> {
            return social.value && social.value.discriminator === 'Instagram' 
        })
        const onClosePanel = ()=> {
            creating.value = false
            social.value = null
        }
        return {
            cal,
            popover,
            closePopover,
            deleteEvent,
            popoverEvent,
            popoverEventId,
            viewCampaign,
            resettingToken,
            creating,
            showFbAddPanel,
            showIgAddPanel,
            onClosePanel,
            associate,
            campaign,
            social,
            onPostAdded,
            labels
        }
    }
}
</script>
<style lang="postcss">
   table, tbody {
        border-color: #2cbeff;
    }
    .fc-theme-standard .fc-scrollgrid, .fc-theme-standard td, .fc-theme-standard th{
        border: 1px solid #2cbeff;
    }
    .custom-popover-header{
        background-color: aliceblue;
    }
    .fc {
        & .fc-event-main-frame {
            background-color: #2cbeff;
            border-color: #2cbeff
            
        }
        & .fc-button-primary{
            color: #fff;
            background-color: #2cbeff;
            border-color: #2cbeff;
            text-transform:uppercase;
            font-size:16px;
        }

        & .green-evt{
            border: 1px solid green;
            & .fc-event-main-frame {
                background-color: green;
                border-color: green;
                
            }
            & .fc-list-event-dot, & .fc-daygrid-event-dot {
                background-color: green;
                border: 4px solid green;
            }

        }
        & .red-evt{
            
            border: 1px solid #dc4939;
            & .fc-event-main-frame {
                
                background-color: #dc4939;
                border: 1px solid #dc4939;
            }
            & .fc-daygrid-event-dot, & .fc-list-event-dot {
                border: 4px solid #dc4939;
            }


        }
        
        & .fc-button-primary:not(:disabled).fc-button-active:focus {
            box-shadow:unset;
        }
        
        & .fc-prev-button, & .fc-next-button, & .fc-today-button, & .fc-dayGridMonth-button, & .fc-listWeek-button {
            border-radius: 0;
        }
        & .fc-toolbar-title{
            font-family: 'Bebas Neue', cursive;
        } 
        & .fc-daygrid-day-frame{
            min-height: fit-content;
        }
        & .fc-daygrid-day.fc-day-today{
            background-color: aliceblue;
        }
        
    }
    
    
</style>    