<template>
    <div class="flex-container flex-col">
        <h3 class="pr16 pb24 pt24 f32 nomargin bebas">Ultime campagne create</h3>
        <ul class="flex-container flex-col nomargin nopadding bg-white">
            <CampaignListElement
                v-for="campaign in latestCampaigns"
                :key="campaign.id"
                :campaign="campaign"
            >
            </CampaignListElement>
            <router-link 
                :to="'/campaigns/'" 
                :class="'button primary flex-container p8 pl16 flex-center-justify'"
            >
                Tutte le campagne
            </router-link>
        </ul>
    </div>
</template>
<script>
/* import {computed} from 'vue'
import {useStore} from 'vuex' */
import CampaignListElement from '@/components/campaigns/elements/CampaignListElement'
export default {
    props:{
        latestCampaigns:{
            type:Object,
            default:()=>{}
        }
    },
    components:{
        CampaignListElement
    },
    setup(){
        /* const store = useStore()
        const latestCampaigns = computed(()=>{
            return store.getters['campaigns/campaigns'].filter((c, index) => index < 5 )
        })
        return{
            latestCampaigns
        } */
    }
}
</script>