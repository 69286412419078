<template>
    <li :class="className">
        <a 
            @click.prevent="onSelect"
            class=" flex-container flex1 overflow-ellipsis w100"
            href="#"
        >
            <div class="flex-container flex-col nomargin pl16 pt8 pb8 pr16 overflow-ellipsis w100">
                <div class="flex-container layout-list-item-title nomargin f24 flex-center-align w100">
                    <i v-if="element.social.discriminator === 'Facebook'" class="lni lni-facebook-original"></i>
                    <i v-else-if="element.social.discriminator === 'Instagram'" class="lni lni-instagram"></i>
                    <span class="f16 pl16 pr16 lh24 overflow-ellipsis w100">{{element.social.name ? element.social.name : 'Nome Social'}}</span>
                    <i v-if="element.campaign?.advertiserUser?.userName" class=" f12 lh24">{{element.campaign.advertiserUser.userName}}</i>
                </div>
                <strong class="lh24 overflow-ellipsis mt8">{{element.campaign.title ? element.campaign.title : 'Nome Campagna'}} </strong>
                
            </div>

        </a>
            <div v-if="canManageSocials" class="flex-container no-grow">
                <InflooButton 
                        v-if="authorized && accepted==='Accepted'"
                        @click.prevent="$emit('insert-post',element)"
                        class="f24"
                >
                    <i class="lni lni-envelope"></i>
                </InflooButton>
                <InflooButton 
                    v-if="authorized && accepted==='Accepted'"
                    @click.prevent="$emit('insert-advert',element)" 
                    class="f24"
                >
                    <i class="lni lni-coin"></i>
                </InflooButton>
                <InflooButton
                    @click.prevent="()=>acceptSocialPressed(element.social)" 
                    v-if="showAcceptBtn"
                    class="confirm f24"
                >
                    <i class="lni lni-checkmark-circle"></i>
                </InflooButton>
                <InflooButton
                    @click.prevent="() => unacceptSocialPressed(element.social)" 
                   v-else-if="showDenyBtn"
                    :className="'button secondary remove-social-from-campaign f24'"
                >
                    <i class="lni lni-ban"></i>
                </InflooButton>
                <InflooButton
                    @click.prevent="() => authorizeSocialPressed(element.social)" 
                    v-if="showAuthorizeBtn"
                    class="confirm f24"
                >
                    <i class="lni lni-thumbs-up"></i>
                </InflooButton>
                 <InflooButton
                    @click.prevent="() => deAuthorizeSocialPressed(element.social)" 
                    v-else-if="showDeAuthorizeBtn"
                    :className="'button secondary remove-social-from-campaign f24'"
                >
                    <i class="lni lni-thumbs-down"></i>
                </InflooButton>
            </div>
    </li>
</template>
<script>

import {computed, inject, ref } from 'vue'
import{
  USER_ROLE_SUPER,
  USER_ROLE_ADMIN,
} from '@/utils/RolesAndPolicies'
import {useCapabilities} from '@/utils/Capabilities'
import InflooButton from '@/components/buttons/InflooButton'
import {useRouter} from 'vue-router'
export default {
    components:{
        InflooButton
    },
    emits:[ 
        'withdraw-campaign', 
        'add-to-campaign', 
        'remove-social-from-campaign',
        'authorize-social', 
        'deauthorize-social',
        'accept-social',
        'unaccept-social',
        'insert-advert',
        'insert-post',

    ],
    props:{
        element:{
            type:Object,
            default:()=>{}
        }
    },
    setup(props,context){
        
        const me = inject('me')
        const router = useRouter()
        const {canManageSocials} = useCapabilities()

        const authorized = ref( props.element.authorized)
        const accepted = ref(props.element.accepted)
        const verified = ref(props.element.social.verified)
        
        const className = computed(()=> {     
            let cls = 'campaign-social'

            if(!authorized.value && authorized.value !== undefined){
                cls += ' to-auth'
            }
            
            if(accepted.value === 'Pending' ){
                cls += ' pending' 
            }
            if(accepted.value === 'Declined' ){
                cls += ' declined' 
            }
            
            if( accepted.value === 'Accepted' && authorized.value === true &&  verified.value){
                cls = 'campaign-social  all-good'
            }
            
            return cls
            
        })

        const onSelect = (e)=>{
            if(e.target.tagName !== 'BUTTON')
            router.push('/campaigns/'+ props.element.campaign.id)
        }


        const showAcceptBtn = computed(()=> {
           return  accepted.value === 'Pending' || accepted.value === 'Declined'
        }) 
        const showDenyBtn = computed(()=> {
           return  accepted.value === 'Accepted'
        }) 
        const showAuthorizeBtn = computed(()=> {
            
            return  !authorized.value 
                && canManageSocials 
                && (accepted.value !== undefined && accepted.value !== 'Candidate')
                    
        })  
        const showDeAuthorizeBtn = computed(()=> {
           
            return  authorized.value && canManageSocials
                    
        }) 
        
        const withdrawCampaign = (social)=>{
            context.emit('withdraw-campaign', social)
        }
        
        const removeSocialFromCampaign = (social)=>{
            context.emit('remove-social-from-campaign', social)
        }

        const acceptSocialPressed = (social) => {
            accepted.value = 'Accepted'
            context.emit('accept-social',  {social:social, campaign:props.element.campaign})
          //  EventsHandler.publish('accept-social', social)
        }
        const unacceptSocialPressed = (social) => {
            accepted.value = 'Declined'
            context.emit('unaccept-social',  {social:social, campaign:props.element.campaign})
           // EventsHandler.publish('unaccept-social', social)
        }
        const authorizeSocialPressed = (social) => {
            authorized.value = true
            context.emit('authorize-social', {social:social, campaign:props.element.campaign})
        }
        const deAuthorizeSocialPressed = (social) => {
            authorized.value = false
            context.emit('deauthorize-social',  {social:social, campaign:props.element.campaign})
        }

        return{
            me,
            USER_ROLE_SUPER,
            USER_ROLE_ADMIN,
            className,
            withdrawCampaign,
            removeSocialFromCampaign,
            authorizeSocialPressed,
            deAuthorizeSocialPressed,
            acceptSocialPressed,
            unacceptSocialPressed,
            canManageSocials,
            showAcceptBtn,
            showDenyBtn,
            showAuthorizeBtn,
            showDeAuthorizeBtn,
            authorized,
            accepted,
            verified,
            onSelect
        }
    }
    
}
</script>

<style lang="postcss" scoped>
.campaign-social{
    &.pending {
        background-color: lightpink;
    }
     & a {
            color: inherit;
            text-decoration: none;
        }
    &.to-auth {
        background-color: rgb(240, 150, 15);
    }
    &.declined {
        background-color:rgb(198, 100, 240);
        color: white; 
        & .layout-list-item-title{
            color: white;
        }
        & .layout-list-item-subtitle, & i{
            color: white;
        }
        
    }
    &.to-auth.pending{
        background-color: rgb(240, 67, 15);
        color: white; 
        & .layout-list-item-title{
            color: white;
        }
        & .layout-list-item-subtitle, & i{
            color: white;
        }
    }
    
    &.to-auth.declined {
        background-color:  rgb(240, 67, 15);
         & .layout-list-item-title{
            color: white;
        }
        & .layout-list-item-subtitle, & i{
            color: white;
        }
    }
    &.all-good {
        background-color: lightgreen;
    }
    & a.confirm {
        text-decoration: none;
        color: #ffffff;
        background-color: #2cbeff;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 10px;
        border: none;

        &:hover{
            background-color: #2f3a3f;
            color:#fff;
        }
    }
    & a.deny {
        background-color: #2f3a3f;
        color: #F2F2F2;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 10px;
        border: none;
        &:hover{
            background-color: #2cbeff;
            color:#fff;
        }
    }
    
}
</style>

