<template>
    <div class="flex-container flex-col campaign-social-widget">
        <h3 class="pr16 pb24 pt24 f32 nomargin bebas">Ultime Campagne proposte </h3>
        <div class="flex-container flex-col scrollable-content last-scrollable">
            <ul class="w100 nomargin nopadding">
                <CampaignSocialWidgetElement 
                    v-for="element, i in elements"
                    :key="'el'+i"
                    class="flex-container nomargin nopadding layout-list-item "
                    :element="element"
                    @insert-post="insertPost"
                    @insert-advert="insertAdvertPost"
                    @accept-social="onAcceptSocial"
                    @unaccept-social="onUnacceptSocial"
                    @authorize-social="onAuthorizeSocial"
                    @deauthorize-social="onDeAuthorizeSocial"
                    
                >
                        
                </CampaignSocialWidgetElement>
            </ul>
        </div>
    </div>
    <FacebookAddPost
        v-if="creating"
        @close="creating = false"
        @add-success="onPostAdded"
        :campaign="campaign"
        :startStep="0"
        :calendarId="creatingCalendarId"
        :social ="selectedSocial"
        @oauth-error="resettingToken = true"
    ></FacebookAddPost>
   <FacebookAddAdvert
        v-if="creatingAdv"
        @close="creatingAdv = false"
        @add-success="onAdvertAdded"
        :campaign="campaign"
        :social ="selectedSocial"
    ></FacebookAddAdvert>
    <FacebookEditAdvert
        v-if="addingAdvPost"
        @close="addingAdvPost = false"
        @add-success="onPostAdded"
        :campaign="campaign"
        :advert ="selectedAdvert"
         @oauth-error="resettingToken = true"
    ></FacebookEditAdvert>
    <FacebookResetToken
        v-if="resettingToken"
        @close="resettingToken = false"
    ></FacebookResetToken>
</template>
<script>
import { computed,/*, */ ref/* , onMounted , watch*/} from 'vue'
import {useStore} from 'vuex'
import CampaignSocialWidgetElement from '@/components/home/CampaignSocialWidgetElement'
import FacebookAddPost from '@/components/campaigns/facebook/panels/FacebookAddPost'
import FacebookAddAdvert from '@/components/campaigns/facebook/panels/FacebookAddAdvert'
import FacebookEditAdvert from '@/components/campaigns/facebook/panels/FacebookEditAdvert'
import FacebookResetToken from '@/components/FacebookResetToken'
import {
    acceptSocial, 
    unacceptSocial, 
    authorizeSocial, 
    deAuthorizeSocial 
} from '@/utils/CampaignFunctions'

export default {
    components:{
        CampaignSocialWidgetElement,
        FacebookAddPost,
        FacebookAddAdvert,
        FacebookEditAdvert,
       FacebookResetToken
    },
    props:{
        latestCampaigns:{
            type:Object,
            default:()=>{}
        }
    },
    
    setup(){
        const store = useStore()
       
         const elements = computed(()=>{
            return store.getters['socialcampaigns/paged']
        }) 
        /**
         * status reset token
         */
        const resettingToken = ref(false)
        /**
         * status sto aggiungendo un post
         */
        const creating= ref(false)
        /**
         * l'advert selezionato
         */
        const selectedAdvert = ref(null)
        /**
         * status sto aggiungendo/creando un adv
         */
        const creatingAdv= ref(false)
        const campaign = ref(null)
        const creatingCalendarId= ref(null)
        const creatingStartStep = ref(0) 
        const addingAdvPost= ref(false)
        
        const onPostAdded = async()=>{
            creating.value = false 
        }
        const selectedSocial = ref(null)
        const onAdvertAdded = async(advert)=>{
            creatingAdv.value = false
            creatingAdv.value = false
            editAdvert(advert)
        }
       
        const insertPost =(element)=>{
            campaign.value=element.campaign
            selectedSocial.value = element.social
            creating.value = true
        }
        

        const insertAdvertPost =(element)=>{
            campaign.value=element.campaign
            selectedSocial.value = element.social
            creatingAdv.value = true
        }
        const editAdvert =(advert)=>{
            selectedAdvert.value = advert
            addingAdvPost.value = true 
        }

        const onAcceptSocial = async (payload)=>{
            
            const {social, campaign} = payload
            await acceptSocial(social, campaign)
            
        }
        const onUnacceptSocial = async (payload)=>{
          
           const {social, campaign} = payload
           await unacceptSocial(social, campaign)
        } 
        const onAuthorizeSocial = async (payload)=>{
           const {social, campaign} = payload
           await  authorizeSocial(social, campaign)
        } 
        
        const onDeAuthorizeSocial = async (payload)=>{
           const {social, campaign} = payload
           await  deAuthorizeSocial(social, campaign)
          
        } 


        return {
            elements,
            creating,
            creatingAdv,
            addingAdvPost,
            resettingToken,
            onPostAdded,
            selectedAdvert,
            onAdvertAdded,
            insertPost,
            insertAdvertPost,
            creatingCalendarId,
            creatingStartStep,
            selectedSocial,
            campaign,
            onAcceptSocial,
            onUnacceptSocial,
            onAuthorizeSocial,
            onDeAuthorizeSocial
        }
    }
}
</script>
<style lang="postcss">
    .last-scrollable{
        height: 474px;
        
        @media (max-width: 1440px) {
            height:158px;
        }
        @media (max-width: 1337px) {
            height:100px
        }
  /* your styles here */
}
</style>