<template>
    <div class="custom-popover hide flex-container flex-col f12" id="custom-popover">
        <div
            class="custom-popover-header flex-container flex-space-between-justyfy"
        >
            <div class="fc-event-time">
                <span></span>
            </div>
            <i class="lni lni-close " @click="$emit('close')"></i>
        </div>
        
        <div class="popover-text" >

        </div>
        <div class="flex-container">
            <InflooButton
                @click="$emit('view-campaign')"
                :className="'button primary pl8 pr8 '"
                id="view"
            >
                <i class="lni lni-eye icon-big"></i>
            </InflooButton>
            <InflooButton
                @click="$emit('insert-post')"
                :className="'button primary pl8 pr8 '"
                id="insert"
            >
                <i class="lni lni-envelope icon-big"></i>
            </InflooButton>
            <InflooButton
                @click="$emit('delete')"
                :className="'button secondary pl8 pr8'"
                id="remove"
            >
                elimina
            </InflooButton>
        </div>
        
    </div>
</template>
<script>
import InflooButton from '@/components/buttons/InflooButton'

export default {
    emits:['close', 'delete', 'insert-post', 'view-campaign'],
    components:{
        InflooButton
    },
    props:{
        event:{
            type:Object,
            default:()=>{}
        }

    }
}
</script>
<style lang="postcss">
    .custom-popover{
        position: absolute;
        width: 250px;
        background-color: white;
        z-index: 9999;
        border: solid 1px lightgray;
        & .button{
            flex:1;
        }
        & i.icon-big {
            transform: scale(2);
        }
    }
    .custom-popover-header{
        padding: 3px 4px;
        background-color: rgba(208, 208, 208, 0.3);
    }
    .popover-text{
        padding: 3px 4px;
    }
 
</style>