<template>
    <div class="flex-container w100 widgets-container">
        <ResizableColumnContainer 
            :maxCol="2" 
            @total-size="onTotalSize"
        >
            <resizable-column 
                :columnIndex="0" 
                @column-resize="homeColumnResize"
                ref="calendarColumn"
                :style="calendarStyle"
            >
                <CalendarCard :events="events" :key="'calendar'+keyCounter"></CalendarCard>
            </resizable-column> 
            <resizable-column 
                :columnIndex="1" 
                @column-resize="homeColumnResize"
                ref="latestColumn"
                :style="latestStyle"
                :resizable="false"
            >
                 <div class="flex-container flex-col pb24 pl24 pr24 bg-aliceblue">
                    <LatestCampaignsWidget :latestCampaigns="latestCampaigns"></LatestCampaignsWidget>
                    <CampaignSocialWidget></CampaignSocialWidget>
                </div>
            </resizable-column> 
        </ResizableColumnContainer>
       
       
    </div>
</template>
<script>
import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import{useCapabilities}from '@/utils/Capabilities'
import {EventsHandler} from '@/utils/EventsHandler'
import CalendarCard from '@/components/home/CalendarCard.vue'
import LatestCampaignsWidget from '@/components/home/LatestCampaignsWidget.vue'
import CampaignSocialWidget from '@/components/home/CampaignSocialWidget.vue'
import ResizableColumn from '@/components/ResizableColumn'
import ResizableColumnContainer from '@/components/ResizableColumnContainer'
import {
    calendarColumn,
    calendarStyle,
    latestColumn,
    latestStyle,
    onTotalSize,
    homeColumnResize

} from '@/utils/ColumnsResizer'
export default {
    components:{
        CalendarCard,
        LatestCampaignsWidget,
        CampaignSocialWidget,
        ResizableColumn,
        ResizableColumnContainer
    },
    setup(){
        const store = useStore()
        const latestCampaigns = computed(()=>{
            return store.getters['campaigns/campaigns'].filter((c, index) => index < 5 )
        })
        const { isLoggedIn } = useCapabilities()

        
        const events = computed(()=>{
            return store.getters['calendar/events']
        })

        const keyCounter = ref(0)
        EventsHandler.subscribe('publish-date', ()=>{
            keyCounter.value++
            
        })
       

        return {
            isLoggedIn,
            latestCampaigns,
            keyCounter,
            events,
            onTotalSize,
            homeColumnResize,
            calendarColumn,
            calendarStyle,
            latestColumn,
            latestStyle,
        } 
    }
}
</script>
<style lang="postcss">
.widgets-container{
    overflow-y:auto ;
}
</style>
